import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// Pages
const HomePage = React.lazy(() => import('../pages/HomePage'));
const Users = React.lazy(() => import('../pages/Users'));
const Publishers = React.lazy(() => import('../pages/Publishers'));
const Blockeds = React.lazy(() => import('../pages/Blockeds'));
const Blocked = React.lazy(() => import('../pages/Blocked'));
const Posts = React.lazy(() => import('../pages/Posts'));
const PackageManagement = React.lazy(() => import('../pages/PackageManagement'));
const AdminManagement = React.lazy(() => import('../pages/AdminManagement'));
const FixedPages = React.lazy(() => import('../pages/FixedPages'));
const SiteSettings = React.lazy(() => import('../pages/SiteSettings'));
const PublisherStatistics = React.lazy(() => import('../pages/PublisherStatistics'));
const Gain = React.lazy(() => import('../pages/Gain'));
const Payments = React.lazy(() => import('../pages/Payments'));
const Payouts = React.lazy(() => import('../pages/Payouts'));
const Support = React.lazy(() => import('../pages/Support'));
const Login = React.lazy(() => import('../pages/Login'));
const Loading = React.lazy(() => import('../pages/Loading'));

const AppRouter = () => {
  return (
    <BrowserRouter>
        <Routes>
            <Route path='/' element={
                <React.Suspense fallback={<Loading/>}>
                    <HomePage />
                </React.Suspense>
            } />
            <Route path='/users' element={
                <React.Suspense fallback={<Loading/>}>
                    <Users />
                </React.Suspense>
            } />
            <Route path='/posts' element={
                <React.Suspense fallback={<Loading/>}>
                    <Posts />
                </React.Suspense>
            } />
            <Route path='/gain' element={
                <React.Suspense fallback={<Loading/>}>
                    <Gain />
                </React.Suspense>
            } />
            <Route path='/payments' element={
                <React.Suspense fallback={<Loading/>}>
                    <Payments />
                </React.Suspense>
            } />
            <Route path='/payouts' element={
                <React.Suspense fallback={<Loading/>}>
                    <Payouts />
                </React.Suspense>
            } />
            <Route path='/package-management' element={
                <React.Suspense fallback={<Loading/>}>
                    <PackageManagement />
                </React.Suspense>
            } />
            <Route path='/admin-management' element={
                <React.Suspense fallback={<Loading/>}>
                    <AdminManagement />
                </React.Suspense>
            } />
            <Route path='/support' element={
                <React.Suspense fallback={<Loading/>}>
                    <Support />
                </React.Suspense>
            } />
            <Route path='/publisher-statistics' element={
                <React.Suspense fallback={<Loading/>}>
                    <PublisherStatistics />
                </React.Suspense>
            } />
            <Route path='/fixed-pages' element={
                <React.Suspense fallback={<Loading/>}>
                    <FixedPages />
                </React.Suspense>
            } />
            <Route path='/site-settings' element={
                <React.Suspense fallback={<Loading/>}>
                    <SiteSettings />
                </React.Suspense>
            } />
            <Route path='/login' element={
                <React.Suspense fallback={<Loading/>}>
                    <Login />
                </React.Suspense>
            } />
            <Route path='/publishers' element={
                <React.Suspense fallback={<Loading/>}>
                    <Publishers />
                </React.Suspense>
            } />
            <Route path='/blockeds' element={
                <React.Suspense fallback={<Loading/>}>
                    <Blockeds />
                </React.Suspense>
            } />
            <Route path='/blocked' element={
                <React.Suspense fallback={<Loading/>}>
                    <Blocked />
                </React.Suspense>
            } />
        </Routes>
    </BrowserRouter>
  )
}

export default AppRouter